import * as React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Container from "../components/container"
import { graphql } from "gatsby"

export const pageQuery = graphql`
  query($slug: String!){
    markdownRemark(fields: { slug: { eq: $slug }}){
      frontmatter {
        title
      }
      html
    }
  }
`

const Legal = ({ data }) => {
  const mdRemark = data.markdownRemark
  return(
    <Layout>
      <SEO title={mdRemark.frontmatter.title}/>
      <Hero
        content={[mdRemark.frontmatter.title]}
      />
      <Container>
        <div
          dangerouslySetInnerHTML={{__html: mdRemark.html}}/>
      </Container>
    </Layout>
  )
}

export default Legal